import { type ReactElement } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { CreativePlaceholderId } from '../../../shared/enums';
import { type NavigationItem as NavigationItemInterface } from '../../../shared/types';
import { type FragmentContext } from '../../types/fragmentContext';
import { ACMBanner } from '../ACMBanner';
import { NavigationGroup } from '../NavigationGroup/NavigationGroup';

const Navigation = (): ReactElement => {
    const {
        breadcrumb = [],
        campaign = [],
        isHome = false,
        isMydays,
        navigationItems,
    } = useFragmentContext<FragmentContext>();

    const navigationBanner =
        !isHome &&
        campaign.find(item => item.placeholderId === CreativePlaceholderId.NavigationBannerDesktop);

    if (navigationItems.length < 1) {
        return <div data-testid="fallback-side-navigation" />;
    }

    const topCrumb = breadcrumb[0];

    // Find correct navigation tree or default to first node
    // We rely on that Erlebniskategorien is the first navigation element
    let navigation = navigationItems[0];

    if (topCrumb) {
        navigation =
            navigationItems.find((item: NavigationItemInterface) =>
                item.children.some((child: NavigationItemInterface) => child.id === topCrumb.id),
            ) ?? navigationItems[0];
    }

    const getLastItem = (item: NavigationItemInterface, level: number): NavigationItemInterface => {
        const childItem = item?.children?.find(child => child.id === breadcrumb[level]?.id);

        if (!childItem) return item;

        return getLastItem(childItem, level + 1);
    };

    const lastItem = getLastItem(navigationItems[0], 0);

    const selectedItemHasChildren = isMydays
        ? lastItem.children.length > 0
        : lastItem.children.length > 0 && breadcrumb.length === 1; // we show third level children only for JS

    return (
        <div className="d-none d-md-block mt-2x">
            {navigationBanner && (
                <ACMBanner
                    image={navigationBanner.image}
                    url={navigationBanner.url}
                    trackingName={navigationBanner.trackingName}
                    campaignName={navigationBanner.campaignName}
                    placeholderId={navigationBanner.placeholderId}
                    countdown={navigationBanner.countdown}
                />
            )}
            <NavigationGroup
                items={navigation.children}
                breadcrumb={breadcrumb}
                itemLevel={1}
                navigationLevel={breadcrumb.length}
                selectedItemHasChildren={selectedItemHasChildren}
            />
        </div>
    );
};

export { Navigation };
